// Created By ND
import ColorsHelper from "../Helper/Colors"

export const ltsStyles = {
    rootDiv: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: '1 1 auto',
        width: '100%',
        height: '100vh',
        overflow: 'auto',
        zIndex: '2',
        backgroundColor: ColorsHelper.bgWhiteClr
    },
    layoutDiv: {
        flex: 1,
        position: 'relative',
        height: '100dvh',
        overflow: 'auto',
        backgroundColor: ColorsHelper.whiteClr,
    },
    layoutAuthDiv: {
        flex: 1,
        position: 'relative',
        backgroundColor: ColorsHelper.bgWhiteClr
    }
}

export default ltsStyles